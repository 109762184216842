import React from "react";
// import { Loading, Tabs, Icon } from "element-react";

class MarketPage extends React.Component {
  state = {};

  render() {
    return <div>MarketPage</div>;
  }
}

export default MarketPage;
