import React from "react";
import { Menu as Nav, Button } from "element-react";
import { NavLink } from 'react-router-dom';

const Navbar = ({ user, handleSignout, handleSignin }) => (
	<Nav mode="horizontal" theme="dark" defaultActive="1">
		<div className="nav-container">
			<Nav.Item index="1">
				<NavLink to="/" className="nav-link">
				 <span className="app-title">
					 <img src="https://icon.now.sh/account_balance/f90" alt="App Icon" className="app-icon"/>
					 College Match U - BETA
				 </span>
				</NavLink>			
			</Nav.Item>

			{/*Navbar items*/}
			<div className="nav-items">
				<Nav.Item index="2">
					{user && <span className="app-user">Hello, {user.username}</span> }
				</Nav.Item>
				<Nav.Item index="3">
					{user ? 
						<Button type="warning" onClick={handleSignout}>Sign Out</Button>
						:
						<Button type="warning" onClick={handleSignin}>Sign In / Up</Button>
					}
				</Nav.Item>
			</div>
		</div>
	</Nav>
);

export default Navbar;
