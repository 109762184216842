// Replaced "isPaid" with "user" for Beta
import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './MatchForm.css';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'element-react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const tableStyles = {
	"textAlign" : 'center',
	"width": '100%',
	margin: 'auto'
}
const rowStyles = {
	"width": '50%',
	"textDecoration": 'underline'
}

class MatchForm extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			showSchoolModal: false,
			coaches: [],
			schoolIndex: -1,
			tableColumns: [
				{
					Header: 'Match Status',
					accessor: 'rank',
					Cell: ({ row }) => {
						let icon = row._original.rank === 1 ? 'https://icon.now.sh/stars/f90'  : row._original.rank === 2 ? "https://icon.now.sh/thumb_up/f90" : "https://icon.now.sh/thumbs_up_down/f90"
						let verbiage = row._original.rank === 1 ? 'TOP 25% MATCH!'  : row._original.rank === 2 ? "Top 75%" : "Below 75%"
						return <span><img src={icon} alt="App Icon" className="app-icon"/> {verbiage}</span>
					}
				},
				{
				Header: 'School Name',
				accessor: 'institution_name'
				}, 
				{
				Header: 'City',
				accessor: 'city'
				}, 
				{
					Header: 'State',
					accessor: 'state'
				}
			]
		}
	}
	
	toggleSchoolModal = (row) => {
		if (row._index >= 0) {
			this.setState({ schoolIndex: row._index})
			if (this.props.matchResults[row._index].sport_name !== "N/A") {
				this.getCoaches(this.props.matchResults[row._index].school_sport_id)
			}
		}
		this.setState(prevState => ({
			showSchoolModal: !prevState.showSchoolModal
		}));
	}

	calcRows = () => {
		const rows = !this.props.user ? 5 : this.props.amount < 5 ? 5 : this.props.amount < 10 ? 10 : 20;
		return rows;
	}

	getCoaches = schoolSportId => {
		const apiString = `https://knkquvijok.execute-api.us-east-1.amazonaws.com/default/getCoaches?schoolSportId=${schoolSportId}`;
		fetch(apiString)
		.then(r => r.json())
				.then((data) =>  {
					this.setState({ coaches: data.data})
					return true
				})
	}

	render () {
		return(
	<div>
		<Card>
			<h1> Yuss! You matched {this.props.amount} schools in the {this.props.region}!</h1>
			{!this.props.user && <h2>Here's three of them:</h2>}
			<p><b>Click a column to sort. Click a school for all info needed to Match U!</b></p>
			<ReactTable className="grow"
    		data={this.props.tableResults}
				columns={this.state.tableColumns}
				defaultPageSize={this.calcRows()}
				getTrProps={(state, rowInfo, column, instance) => {
					if (typeof rowInfo !== "undefined") {
							return {
									onClick: (e) => {
										this.toggleSchoolModal(rowInfo.row)
									},
									style: {
											background: rowInfo.original.rank === 1 ? 'rgb(65, 244, 109, 0.3)' : rowInfo.original.rank === 2 ? 'rgb(66, 131, 244, 0.3)' : 'rgb(244, 244, 65, 0.3)',
											color: rowInfo.index === this.state.selected ? 'white' : 'black'
									}
							} 	
					}
					else {
							return {
									style: {
											background: 'white',
											color: 'black'
									},
							}
					}
			}}
			/>
			{this.state.showSchoolModal && 
				
				<Dialog
          onClose={this.toggleSchoolModal}
          aria-labelledby="customized-dialog-title"
          open={this.state.showSchoolModal}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.toggleSchoolModal}>
					{this.props.matchResults[this.state.schoolIndex].institution_name}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
						<b>Address: </b>{this.props.matchResults[this.state.schoolIndex].addr_txt}&nbsp; 
						{this.props.matchResults[this.state.schoolIndex].city_txt}&nbsp;
						{this.props.matchResults[this.state.schoolIndex].state_cd},&nbsp;
						{this.props.matchResults[this.state.schoolIndex].zip_cd}&nbsp;&nbsp;
						<br />
						<b>Phone: </b>
						({this.props.matchResults[this.state.schoolIndex].gen_tel.substr(0,3)})
						{this.props.matchResults[this.state.schoolIndex].gen_tel.substr(3,3)}-
						{this.props.matchResults[this.state.schoolIndex].gen_tel.substr(6,4)}
						<br />
						<b>URL: </b>
						<a target="_blank" rel="noopener noreferrer" href={"https://" +this.props.matchResults[this.state.schoolIndex].school_url}>{this.props.matchResults[this.state.schoolIndex].school_url}</a>
						<br />
						<b>Classification: </b>
						{this.props.matchResults[this.state.schoolIndex].classification_name}
						<br />
						<b>Football Conference: </b>
						{this.props.matchResults[this.state.schoolIndex].football_conference_name}
						<br />
						<b>Basketball Conference: </b>
						{this.props.matchResults[this.state.schoolIndex].basketball_conference_name}
						<br />
						<b>Baseball Conference: </b>
						{this.props.matchResults[this.state.schoolIndex].basketball_conference_name}
						<br />
						<b>Track Conference: </b>
						{this.props.matchResults[this.state.schoolIndex].track_conference_name}
						</Typography>
          <hr />
					<table
					style={tableStyles}
				>
					<tbody>
					<tr>
						<th	style={rowStyles}>
							Admissions Data
						</th>
						<th	style={rowStyles}>
							Tuition Data	
						</th>
					</tr>
					<tr>
						<td>Total Admissions: {this.props.matchResults[this.state.schoolIndex].admissions_total}</td>
						<td>In State Tuition: &#36;{this.props.matchResults[this.state.schoolIndex].instate_tuition}.00</td>
					</tr>
					<tr>
						<td>Number of Applicants: {this.props.matchResults[this.state.schoolIndex].applicants_total}</td>
						<td>In State Fees: &#36;{this.props.matchResults[this.state.schoolIndex].instate_fees}.00</td>
					</tr>
					<tr>
						<td>New Freshman Female: {this.props.matchResults[this.state.schoolIndex].enrolled_female}</td>
						<td>In District Tuition: &#36;{this.props.matchResults[this.state.schoolIndex].indist_tuition}.00</td>
					</tr>
					<tr>
						<td>New Freshman Male: {this.props.matchResults[this.state.schoolIndex].enrolled_male}</td>
						<td>In District Fees: &#36;{this.props.matchResults[this.state.schoolIndex].indist_fees}.00</td>
						
					</tr>
					<tr>
						<td>Combined ACT Top 25%: {this.props.matchResults[this.state.schoolIndex].act_composite_75}</td>
						<td>Out of State Tuition: &#36;{this.props.matchResults[this.state.schoolIndex].outstate_tuition}.00</td>
					</tr>
					<tr>
						<td>Combined ACT 75%: {this.props.matchResults[this.state.schoolIndex].act_composite_25}</td>
						<td>Out of State Fees: &#36;{this.props.matchResults[this.state.schoolIndex].outstate_fees}.00</td>
					</tr>
					<tr>
						<td>Combined SAT Top 25%: {this.props.matchResults[this.state.schoolIndex].sat_combined_75}</td>
						<td>Room & Board: &#36;{this.props.matchResults[this.state.schoolIndex].oncampus_roomboard}.00</td>
					</tr>
					<tr>
						<td>Combined SAT 75%: {this.props.matchResults[this.state.schoolIndex].sat_combined_25}</td>
						<td>Books & Supplies: &#36;{this.props.matchResults[this.state.schoolIndex].books_supply}.00</td>
					</tr>
					</tbody>
				</table>
				{this.props.matchResults[this.state.schoolIndex].sport_name !== "N/A" &&
				<table
					style={tableStyles}
				>
					<tbody>
					<tr colSpan="2">
						<td>&nbsp;</td>
					</tr>
						<tr colSpan="2"><td><b><u>Sport Info</u></b></td></tr>
					<tr
						colSpan="2"
					>
					<td><b>Sport: </b>{this.props.matchResults[this.state.schoolIndex].sport_name}</td>
					</tr>
					<tr
						colSpan="2"
					>
					<td><b>Team URL: </b><a target="_blank" rel="noopener noreferrer" href={"https://" +this.props.matchResults[this.state.schoolIndex].sport_home_url}>{this.props.matchResults[this.state.schoolIndex].sport_home_url}</a></td>
					</tr>
					<tr colSpan="2">
						<td>&nbsp;</td>
					</tr>
					{this.state.coaches && this.state.coaches.length > 0 && (
      			<ParseCoaches data={this.state.coaches} />
    			)}
				</tbody>
				</table>
			}
					</DialogContent>
        </Dialog>
			}
				
			<div className="text-center">
			{this.props.user ? <h2>Thanks for Being a BETA Tester! Search On!</h2>
			: <h2>Signup Now to Get Detailed Info for All Your Matches! </h2>}
	
				<br />
				<div className="text-center">
					{!this.props.user && <Button type="warning" onClick={this.props.handleSignin}>Sign In / Up</Button>}
					<Button type="info" onClick={this.props.backBtn}>Back to Search</Button>
				</div>
			</div>
			
		</Card>
	</div>
	)}
}


const ParseCoaches = ({data}) => (
				<React.Fragment>
					<tr colSpan="2"><td><b><u>Coaches Info</u></b></td></tr>
					{data.map((coach, i) => (
						<React.Fragment key={i+60}>
						<tr colSpan="2" key={i}>
							<td>&nbsp;</td>
						</tr>
						<tr colSpan="2" key={i+10}>
							<td><b>Coach Name: </b>{coach.coach_name}</td>
						</tr>
						<tr colSpan="2" key={i+20}>
							<td><b>Coach Title: </b>{coach.coach_title}</td>
						</tr>					
						<tr colSpan="2" key={i+30}>
							<td><b>Coach Email: </b><a href={"mailto:" + coach.coach_email}>{coach.coach_email}</a></td>
						</tr>
						<tr colSpan="2" key={i+40}>
							<td><b>Coach Phone: </b>{coach.coach_phone}</td>
						</tr>
						</React.Fragment>
					))}
				</React.Fragment>
		);
		
	
export default MatchForm
