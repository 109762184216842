import React, { Component } from "react";
import AuthSlide from "./AuthSlide";
import { Redirect } from "react-router";
import ProductCheckout from "./ProductCheckout";
import history from "../history";
import { StripeProvider, Elements } from "react-stripe-elements";
import { Route53Resolver } from "aws-sdk/clients/all";

const stripeConfig = {
  currency: "USD",
  publishableAPIKey: "pk_live_YMxkKEUfsr2WGxRm20bOnapM00DPfX1nZB"
};

export class SignupSteps extends Component {
  state = {
    step: 1
  };

  //Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  //Proceed to next step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields Change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };
  goHome = () => {
		history.push("/");
  };

  render() {
		const { user, isPaid } = this.props;
		// Removing for Beta
		// isPaid && this.goHome();
		// user && this.goHome();
		// return <AuthSlide />;
    return !user ? (
      <AuthSlide />
    ) : (
			<Redirect to="/" />
		)
      // <StripeProvider apiKey={stripeConfig.publishableAPIKey}>
      //   <Elements>
			// 		<ProductCheckout 
			// 		email={this.props.user.attributes.email}
			// 		username={this.props.user.username} 
			// 		getUserData={this.props.getUserData}
			// 		validPromo={this.props.validPromo}
			// 		/>
      //   </Elements>
      // </StripeProvider>
    // );
  }
}
export default SignupSteps;
