import React from "react";
import "./App.css";
import { Auth, Hub } from "aws-amplify";
import { Router, Route } from 'react-router-dom';
import HomePage from './pages/HomePageNew';
import SchoolPage from './pages/SchoolPage';
import Terms from './pages/Terms';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SignupSteps from './components/SignupSteps';
import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga';

export const history = createHistory()
ReactGA.initialize('UA-146771507-1');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname)
});

class App extends React.Component {
  state = {
		user: null,
		isPaid: false
	};

	componentDidMount() {
		this.getUserData();
		Hub.listen('auth', this, 'onHubCapsule');
	}

	componentWillUnmount () {
		Hub.remove('auth', this, 'onHubCapsule');
	}

	getUserData = async () => {
		
		const user = await Auth.currentAuthenticatedUser();
		user ? this.setState({ user }) : this.setState({ user: null });
		// Removing for Beta
		// user &&  this.isPaid(user.username);
	}

	onHubCapsule = capsule => {
		switch(capsule.payload.event) {
			case "signIn":
				this.getUserData();				
				break;
			case "signUp":
				break;
			case "signOut":
				this.setState({ user: null, isPaid: false });
				break;
			default:
				return;
		}
	}

	isPaid = (username) => {
		const apiString = `https://2ian4nmg7h.execute-api.us-east-1.amazonaws.com/default/isPaid?username=${username}`;
		fetch(apiString)
		.then(r => r.json())
				.then((data) => {
					this.setState({ isPaid: data.isPaid});
				});
	};

	handleSignout = async () => {
		try{
			await Auth.signOut();
			history.push({pathname:"/"});
		} catch (err) {
			console.error("Error signing out user" , err);
		}
	}

	handleSignin = () => {
		// Removing payment for Beta
		// !this.state.isPaid ?
		!this.state.user ?
		history.push({
			pathname:"/steps"			
		 }) : 
			 history.push({pathname:"/"})
			 	
		}

  render() {
		const { user } = this.state;
		return (
			
			<Router history={history}>
				<React.Fragment>
				
					{/*navigation*/}
					<Navbar user={user} handleSignout={this.handleSignout} handleSignin={this.handleSignin}/>
				
				{/*Routes*/}
					<div name="main" className="app-container">
						<Route exact path="/" render={(props) => <HomePage {...props} user={user} handleSignin={this.handleSignin} isPaid={this.state.isPaid} />} />
						<Route path="/faq" component={SchoolPage} />
						<Route path="/terms" component={Terms} />
						<Route path="/steps" render={(props) => <SignupSteps {...props} user={user} handleSignin={this.handleSignin} isPaid={this.state.isPaid} getUserData={this.getUserData} />} />
					</div>
					<Footer />
				</React.Fragment>
			</Router>
		);
  }
}

//export default withAuthenticator(App, true, [], null, theme);
export default App;