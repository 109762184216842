import React from 'react';
import Amplify from "aws-amplify";
import { Authenticator, AmplifyTheme } from 'aws-amplify-react';
import config from "../static-aws-exports";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

Amplify.configure(config);

const theme = {
	...AmplifyTheme,
	navBar: {
		...AmplifyTheme.navBar,
		backgroundColor: '#fcc0cb'
	},
	button: {
		...AmplifyTheme.button,
		backgroundColor: "var(--amazonOrange)"
	},
	sectionBody: {
		...AmplifyTheme.sectionBody,
		padding: "5"
	},
	sectionHeader: {
		...AmplifyTheme.sectionHeader,
		backgroundColor: "var(--squidInk)"
	}
};

const styles = {
  card: {
		minWidth: 275,
		maxwidth: 360
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

function AuthSlide(props) {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
				<Authenticator 
					theme={theme}
					authState="signUp"	
				/>			
      </CardContent>
    </Card>
  );
}

AuthSlide.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AuthSlide);
