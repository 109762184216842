// Replaced "isPaid" with "user" for Beta
import React from "react";
// prettier-ignore
import { Form, Button, Select } from 'element-react';
import ReactTooltip from 'react-tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
  root: {
    width: 164,
  },
  input: {
    width: 42,
  },
});

const SearchForm = (props) => {
	const classes = useStyles();
	const [gpaValue, setGpaValue] = React.useState(props.gpa);
	const [satrwValue, setsatrwValue] = React.useState(props.satrw);
	const [satMathValue, setsatMathValue] = React.useState(props.satMath);
	const [actMathValue, setactMathValue] = React.useState(props.actMath);
	const [actEnglishValue, setactEnglishValue] = React.useState(props.actEnglish);
	const [actReadingValue, setactReadingValue] = React.useState(props.actReading);
	const [actScienceValue, setactScienceValue] = React.useState(props.actScience);

  const handleGpaSliderChange = (event, newValue) => {
		setGpaValue(newValue);
		props.gpaChange(newValue);
  };

  const handleGpaInputChange = event => {
		setGpaValue(event.target.value === '' ? '' : Number(event.target.value));
		props.gpaChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleGpaBlur = () => {
    if (gpaValue < 0) {
			setGpaValue(0);
			props.gpaChange(0);
    } else if (gpaValue > 5) {
			setGpaValue(5);
			props.gpaChange(5);
    }
	};
	
	const handlesatrwSliderChange = (event, newValue) => {
		setsatrwValue(newValue);
		props.satrwChange(newValue);
  };

  const handlesatrwInputChange = event => {
		setsatrwValue(event.target.value === '' ? '' : Number(event.target.value));
		props.satrwChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handlesatrwBlur = () => {
    if (satrwValue < 0) {
			setsatrwValue(0);
			props.satrwChange(0);
    } else if (satrwValue > 800) {
			setsatrwValue(800);
			props.satrwChange(800);
    }
	};
	
	const handlesatMathSliderChange = (event, newValue) => {
		setsatMathValue(newValue);
		props.satMathChange(newValue);
  };

  const handlesatMathInputChange = event => {
		setsatMathValue(event.target.value === '' ? '' : Number(event.target.value));
		props.satMathChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handlesatMathBlur = () => {
    if (satMathValue < 0) {
			setsatMathValue(0);
			props.satMathChange(0);
    } else if (satMathValue > 800) {
			setsatMathValue(800);
			props.satMathChange(800);
    }
	};

	const handleactScienceSliderChange = (event, newValue) => {
		setactScienceValue(newValue);
		props.actScienceChange(newValue);
  };

  const handleactScienceInputChange = event => {
		setactScienceValue(event.target.value === '' ? '' : Number(event.target.value));
		props.actScienceChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleactScienceBlur = () => {
    if (actScienceValue < 0) {
			setactScienceValue(0);
			props.actScienceChange(0);
    } else if (actScienceValue > 36) {
			setactScienceValue(36);
			props.actScienceChange(36);
    }
	};

	const handleactMathSliderChange = (event, newValue) => {
		setactMathValue(newValue);
		props.actMathChange(newValue);
  };

  const handleactMathInputChange = event => {
		setactMathValue(event.target.value === '' ? '' : Number(event.target.value));
		props.actMathChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleactMathBlur = () => {
    if (actMathValue < 0) {
			setactMathValue(0);
			props.actMathChange(0);
    } else if (actMathValue > 36) {
			setactMathValue(36);
			props.actMathChange(36);
    }
	};

	const handleactReadingSliderChange = (event, newValue) => {
		setactReadingValue(newValue);
		props.actReadingChange(newValue);
  };

  const handleactReadingInputChange = event => {
		setactReadingValue(event.target.value === '' ? '' : Number(event.target.value));
		props.actReadingChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleactReadingBlur = () => {
    if (actReadingValue < 0) {
			setactReadingValue(0);
			props.actReadingChange(0);
    } else if (actReadingValue > 36) {
			setactReadingValue(36);
			props.actReadingChange(36);
    }
	};

	const handleactEnglishSliderChange = (event, newValue) => {
		setactEnglishValue(newValue);
		props.actEnglishChange(newValue);
  };

  const handleactEnglishInputChange = event => {
		setactEnglishValue(event.target.value === '' ? '' : Number(event.target.value));
		props.actEnglishChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleactEnglishBlur = () => {
    if (actEnglishValue < 0) {
			setactEnglishValue(0);
			props.actEnglishChange(0);
    } else if (actEnglishValue > 36) {
			setactEnglishValue(36);
			props.actEnglishChange(36);
    }
	};
	const titleStyle = {
		marginBottom: 5,
		marginTop: 0
	};
	
    return (
			<React.Fragment>
				<div className="market-header">
					<h1 className="market-title">
						Find Your Match!
						<Button
							type="text"
							icon="search"
							className="market-title-button"
						/>
					</h1>
					<h3 style={titleStyle}>Welcome to College Match U!</h3>
					<h4 style={titleStyle}>{props.user ? 'Thanks for testing our BETA! Match on!' : 'Find Your University Now'}</h4>
					<h4 style={titleStyle}>Just provide GPA, ACT, SAT, or both!</h4>
					{props.noResults && <h2>Sorry, Your Query Had No Results. Try Again!</h2>}
				</div>
				<div>				
					<Form labelPosition="top" className="flex-center">
						<span className="m-1">GPA</span>						
						<div 
							data-tip="From 0.0 to 5.0"
							className="flex"
						>							
							<Form.Item label="Grade Point Average" className="mx-1">
							<div className={classes.root}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									
								</Grid>
								<Grid item xs>
									<Slider
										value={props.gpa}
										onChange={handleGpaSliderChange}
										aria-labelledby="GPA-Slider"
										step={.01}
										min={0}
										max={5.0}
									/>
								</Grid>
								<Grid item>
									<Input
										className={classes.input}
										value={gpaValue}
										margin="dense"
										onChange={handleGpaInputChange}
										onBlur={handleGpaBlur}
										inputProps={{
											step: .01,
											min: 0,
											max: 5,
											type: 'number',
											'aria-labelledby': 'input-slider',
										}}
									/>
								</Grid>
							</Grid>
						</div>			
							</Form.Item>	
						</div>
						<span className="m-1">SAT Scores</span>						
						<div 
							className="flex"
							data-tip="From 0 to 800"
						>							
																		
							<Form.Item label="Reading & Writing" className="mx-1">
							<div className={classes.root}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									
								</Grid>
								<Grid item xs>
									<Slider
										value={props.satrw}
										onChange={handlesatrwSliderChange}
										aria-labelledby="satrw-Slider"
										step={10}
										min={0}
										max={800}
									/>
								</Grid>
								<Grid item>
									<Input
										className={classes.input}
										value={satrwValue}
										margin="dense"
										onChange={handlesatrwInputChange}
										onBlur={handlesatrwBlur}
										inputProps={{
											step: 10,
											min: 0,
											max: 800,
											type: 'number',
											'aria-labelledby': 'input-slider',
										}}
									/>
								</Grid>
							</Grid>
						</div>				
							</Form.Item>
							<Form.Item label="Math" className="mx-1">
								
							<div className={classes.root}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									
								</Grid>
								<Grid item xs>
									<Slider
										value={props.satMath}
										onChange={handlesatMathSliderChange}
										aria-labelledby="satMath-Slider"
										step={10}
										min={0}
										max={800}
									/>
								</Grid>
								<Grid item>
									<Input
										className={classes.input}
										value={satMathValue}
										margin="dense"
										onChange={handlesatMathInputChange}
										onBlur={handlesatMathBlur}
										inputProps={{
											step: 10,
											min: 0,
											max: 800,
											type: 'number',
											'aria-labelledby': 'input-slider',
										}}
									/>
								</Grid>
							</Grid>
						</div>					
							</Form.Item>
						</div>
						<span className="m-1">ACT Scores</span>				
						<div className="flex" data-tip="From 0 to 36">					
							<Form.Item label="Reading" className="mx-1">
							<div className={classes.root}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									
								</Grid>
								<Grid item xs>
									<Slider
										value={props.actReading}
										onChange={handleactReadingSliderChange}
										aria-labelledby="actReading-Slider"
										step={1}
										min={0}
										max={36}
									/>
								</Grid>
								<Grid item>
									<Input
										className={classes.input}
										value={actReadingValue}
										margin="dense"
										onChange={handleactReadingInputChange}
										onBlur={handleactReadingBlur}
										inputProps={{
											step: 1,
											min: 0,
											max: 36,
											type: 'number',
											'aria-labelledby': 'input-slider',
										}}
									/>
								</Grid>
							</Grid>
						</div>							
							</Form.Item>
							<Form.Item label="English" className="mx-1">
										
							<div className={classes.root}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									
								</Grid>
								<Grid item xs>
									<Slider
										value={props.actEnglish}
										onChange={handleactEnglishSliderChange}
										aria-labelledby="actEnglish-Slider"
										step={1}
										min={0}
										max={36}
									/>
								</Grid>
								<Grid item>
									<Input
										className={classes.input}
										value={actEnglishValue}
										margin="dense"
										onChange={handleactEnglishInputChange}
										onBlur={handleactEnglishBlur}
										inputProps={{
											step: 1,
											min: 0,
											max: 36,
											type: 'number',
											'aria-labelledby': 'input-slider',
										}}
									/>
								</Grid>
							</Grid>
						</div>							
							</Form.Item>
						</div>
						<div className="flex" data-tip="From 0 to 36">					
							<Form.Item label="Math" className="mx-1">
								
							<div className={classes.root}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									
								</Grid>
								<Grid item xs>
									<Slider
										value={props.actMath}
										onChange={handleactMathSliderChange}
										aria-labelledby="actMath-Slider"
										step={1}
										min={0}
										max={36}
									/>
								</Grid>
								<Grid item>
									<Input
										className={classes.input}
										value={actMathValue}
										margin="dense"
										onChange={handleactMathInputChange}
										onBlur={handleactMathBlur}
										inputProps={{
											step: 1,
											min: 0,
											max: 36,
											type: 'number',
											'aria-labelledby': 'input-slider',
										}}
									/>
								</Grid>
							</Grid>
						</div>							
							</Form.Item>
							<Form.Item label="Science" className="mx-1">
							
							<div className={classes.root}>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									
								</Grid>
								<Grid item xs>
									<Slider
										value={props.actScience}
										onChange={handleactScienceSliderChange}
										aria-labelledby="actScience-Slider"
										step={1}
										min={0}
										max={36}
									/>
								</Grid>
								<Grid item>
									<Input
										className={classes.input}
										value={actScienceValue}
										margin="dense"
										onChange={handleactScienceInputChange}
										onBlur={handleactScienceBlur}
										inputProps={{
											step: 1,
											min: 0,
											max: 36,
											type: 'number',
											'aria-labelledby': 'input-slider',
										}}
									/>
								</Grid>
							</Grid>
						</div>				
							</Form.Item>
						</div>
						<span className="m-1">Academic or Athletic, Division, Gender, and Region</span>
						<div className='flex'>
							<Form.Item label ="Select A Sport" className="mx-1">
								<Select 
									onChange={props.sportChange}
									value={props.sport}
								>
									<Select.Option disabled={!props.user} label="Academic" value="Academic">Academic Search</Select.Option>
									<Select.Option label="Baseball" value="Baseball">Baseball</Select.Option>
									<Select.Option label="Softball" value="Softball">Softball</Select.Option>
									<Select.Option label="VolleyBall" value="VolleyBall">VolleyBall</Select.Option>
									<Select.Option label="Soccer" value="Soccer">Soccer</Select.Option>
									<Select.Option label="Golf" value="Golf">Golf</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item label ="Select Region" className="mx-1">
								<Select 
									value={props.region}
									onChange={props.regionChange}
								>
									<Select.Option label="West" value="West">West</Select.Option>
									<Select.Option label="MidWest" value="MidWest">MidWest</Select.Option>
									<Select.Option label="NorthEast" value="NorthEast">NorthEast</Select.Option>
									<Select.Option label="South" value="South">South</Select.Option>
								</Select>
							</Form.Item>
							</div>
							{props.sport !== "Academic" &&
							<div className='flex'>
							<Form.Item label ="Select A Division" className="mx-1">
								<Select 
									onChange={props.divisionChange}
									value={props.division}
								>
									<Select.Option disabled={!props.user} label="NCAA Division 1" value="1">NCAA Division 1</Select.Option>
									<Select.Option disabled={!props.user} label="NCAA Division 2" value="2">NCAA Division 2</Select.Option>
									<Select.Option label="NCAA Division 3" value="3">NCAA Division 3</Select.Option>
									<Select.Option disabled={!props.user} label="NAIA" value="4">NAIA</Select.Option>
									<Select.Option disabled={!props.user} label="JC" value="5">Junior Colleges</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item label ="Gender" className="mx-1">
								<Select 
									onChange={props.genderChange}
									value={props.gender}
								>
									<Select.Option label="Female" value="F">Female</Select.Option>
									<Select.Option label="Male" value="M">Male</Select.Option>									
								</Select>
							</Form.Item>
						</div>
					}
					
					{!props.user && <> <br /><div className="flex"><br /><a href="/steps">Sign up now </a> &nbsp; for FULL search and ALL info! Or test drive by hitting Submit</div> </>}
												
						<br />
						<Button
						type="primary"
						onClick={props.submit}	
						>
							Submit
						</Button>
					</Form>
				</div>
				<ReactTooltip place="left" type="info" effect="solid"/>
			</React.Fragment>
		)
  }


export default SearchForm;