import React, { Component } from "react";
// prettier-ignore
import { Card, Button } from "element-react";
import { Checkbox, Input } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { CardElement, injectStripe } from "react-stripe-elements";
import { API } from "aws-amplify";
import Loader from 'react-loader-spinner';
import history from '../history';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from "../components/ChangingProgressProvider";

const stripeConfig = {
  currency: "USD",
  publishableAPIKey: "pk_live_YMxkKEUfsr2WGxRm20bOnapM00DPfX1nZB"
};
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#124770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#1ab7c4"
        }
      },
      invalid: {
        color: "#c23d4b"
      }
    }
  };
};

const checkoutDivStyle = {
  maxWidth: 400,
  padding: 25,
  margin: "auto",
  textAlign: "center",
  justifyContent: "center"
};

class ProductCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      plan: "",
      price: "",
      terms: false,
      yearly: false,
			monthly: false,
			beginPay: false,
			successPay: false,
			promo: ''
    };
		this.submit = this.submit.bind(this);
		this.promoChange = this.promoChange.bind(this);
		this.promoValid = this.promoValid.bind(this);
  }

  canSubmit = () => {
		let planPicked = this.state.monthly ? true : this.state.yearly;
		this.state.terms && planPicked ? this.setState({ canSubmit: true })
      : this.setState({ canSubmit: false });
	};
	
	promoChange(e) {
		this.setState({
			promo: e.target.value.toUpperCase()
		});
	}
	promoValid() {
		return this.state.promo.toUpperCase() === "IKNOWMARK"
	}

	async submit(ev) {
		let { token } = await this.props.stripe.createToken({ name: "Name" });
		this.setState({beginPay: true});
    let result = await API.post("charge", "/charge", {
      body: {
				token,
				coupon: this.state.promo,
				email: this.props.email,
				username: this.props.username,
        charge: {
          currency: stripeConfig.currency,
          amount: this.state.price,
          description: this.state.plan
        }
      }
		});
		console.log(result, "result here")
    if (true) {
      this.setState({ 
				successPay: true,
				beginPay: false
			}, 
				function () {	
					this.props.getUserData();
					setTimeout(history.push({pathname:"/"}), 2000);
			})
    };
  }
		

  termsChecked = e => {
		this.setState({ terms: e.target.checked }, function() {this.canSubmit()});
  };

  yearlyChecked = e => {
    this.setState({
      yearly: e.target.checked,
      monthly: false,
      plan: "yearly",
      price: "9900"
		}, 
		function() {this.canSubmit()});
  };

  monthlyChecked = e => {
    this.setState({
      monthly: e.target.checked,
      yearly: false,
      plan: "monthly",
			price: "1499",
			promo:""
		},
		function() {this.canSubmit()});
	};

  render() {
    const offerStyle = {
      color: "red",
      fontSize: "22px"
		};
		const loaderStyles = {			
			position : "fixed",
			top : "40%",
			left : "40%",
			justifyContent: "center",
			display: "flex"
	};
    return (
			<>
			{this.state.beginPay && (
				<React.Fragment>
				<div className="text-center"><h3>Please Wait (Verification can take up to 15 seconds)</h3></div>
				<div style={loaderStyles}>
					
					<ChangingProgressProvider interval={900} values={[0, 12, 25, 38, 45, 60, 75, 87, 100]}>
					{percentage => (
						<CircularProgressbar 
							value={percentage} 
							text={`${percentage}%`} 
							styles={buildStyles({
								// Rotation of path and trail, in number of turns (0-1)
								rotation: 0.12,
								
						
						 
								// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
								strokeLinecap: 'butt',
						 
								// Text size
								textSize: '16px',
						 
								// How long animation takes to go from one percentage to another, in seconds
								pathTransitionDuration: 0.9,
						 
								// Can specify path transition in more detail, or remove it entirely
								// pathTransition: 'none',
						 
								// Colors
								pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
								textColor: '#50bfff',
								trailColor: '#d6d6d6',
								backgroundColor: '#3e98c7',
							})}
						/>
					)}
				</ChangingProgressProvider>
				</div>
				</React.Fragment>
			)}
			{this.state.successPay && (
				<div style={loaderStyles}>
				<Loader 
					type="Hearts"
					height="200"	
					width="200"
					color="green"
				/>   
				</div>
			)}
			{!this.state.beginPay && !this.state.successPay && (
        <div className="text-center">
          <h2>Select a Plan</h2>
          <div className="card-list">
            <div className="card-container">
              <Card bodystyle={{ padding: 0, minWidth: "200px" }}>
                <div className="card-body">
                  <div className="items-center">
                    <img
                      src={`https://icon.now.sh/account_balance/f90`}
                      alt="Icon"
                      className="icon"
                    />
                    College Match U
                  </div>
                  <h3 className="m-0">1 Month Unlimited</h3>
                  <h4 className="m-0">Searching and Reporting</h4>
                  <p>
                    Get all of the up to date data about all of your matches!
                    Tuition and fees, where you rank, etc.
                  </p>
                  <div className="text-center">
										<Checkbox 
											onChange={this.monthlyChecked} 
											checked={this.state.monthly}
										/>
                    $14.99 / Month
                  </div>
                </div>
              </Card>
            </div>
            <div className="card-container">
              <Card bodystyle={{ padding: 0, minWidth: "200px" }}>
                <div className="card-body">
                  <div className="items-center" style={offerStyle}>
                    <img
                      src={`https://icon.now.sh/codeigniter/f00`}
                      alt="Icon"
                      className="icon"
                    />
                    SAVE $10 NOW!
                  </div>
                  <h3 className="m-0">6 Months Unlimited</h3>
                  <h4 className="m-0">Searching and Reporting</h4>
                  <p>
                    Signup for the season and save a few bucks
                  </p>
                  <br />
                  <div className="text-center">
										<Checkbox 
											onChange={this.yearlyChecked}
											checked={this.state.yearly}
										/>
                    {this.state.promo === "IKNOWMARK" ? '$74.94' : this.state.promo ===  "INFLUENCER" ? '$49.99' : '$79.94'} / Six Months
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="text-center">
            <Checkbox
              data-tip="You must accept in order to subscribe"
              onChange={this.termsChecked}
            />
            I accept the College Match U{" "}
            <a href="/terms" target="_blank">
              Terms of Service
            </a>
					</div>
					<div className="text-center">
						Have a Promo Code?{" "}
						<Input 
							disabled={!this.state.yearly}
							onChange={this.promoChange}
						/>
						{this.state.promo === "IKNOWMARK" ? " (Saved $5!)" : this.state.promo === "INFLUENCER" ? " (Saved $29.95)" : ""}
					</div>
          <div style={checkoutDivStyle}>
            <div className="text-center">
              <CardElement {...createOptions()} />
            </div>
            <div className="text-center">
              <br />
              <Button
                disabled={!this.state.canSubmit}
								type="info"
								value={this.state.promo}
                onClick={this.submit}
              >
                Subscribe Now
              </Button>
            </div>
          </div>
				</div>
				)}
        <ReactTooltip place="left" type="info" effect="solid" />
      </>
    );
  }
}

export default injectStripe(ProductCheckout);
