import React from "react";


class Terms extends React.Component {
  render() {
    return (
			<>
			<div>
  <h1>Terms of Service</h1>
	<p>Welcome to College Match U! </p>
	
	<p>Our Beta program may end at any time and is not a promise of continued free usage of the platform</p>

  <p>These Terms of Service constitute a legally binding agreement (the “Terms”) between you and College Match U (“College Match U,” “we,” “us” or “our”) governing your use of College Match U applications, websites, contents, products, and/or services (the “Services”). By accessing or using the Services, you agree to be bound by these Terms. Please read them carefully.</p>

  <p>College Match U provides an online search tool that allows individuals to query for colleges that are a match for them based on some criteria.</p>

  <p>Please be advised: These Terms contain provisions that govern how claims that you and College Match U have against each other can be brought (see below). These provisions will, with limited exception, require that you submit claims you have against College Match U to binding and final arbitration on an individual basis, not as a plaintiff or class member in any class, group or representative action or proceeding.</p>

  <p>College Match U may amend the Terms and modify or update the Services from time to time. Your continued use of the Services after any such changes are posted here will constitute your acceptance of the amended Terms.</p>

  <p>By agreeing to these Terms, you expressly acknowledge that you understand the Terms (including the dispute resolution and arbitration provisions contained herein) and accept all of them. If you do not agree to be bound by these Terms, you may not use or access the Services.</p>

  <h3>YOUR REPRESENTATIONS AND WARRANTIES</h3>

  <p>You represent and warrant that you are at least 13 years old and legally entitled to enter these Terms, and that you have the authority and capacity to enter into and abide by these Terms.</p>

  <p>You further agree and warrant that:</p>

  <p><strong>Your Information.</strong> All information that you provide to College Match U or through the Services is true and accurate, and you will maintain that information up-to-date. You will provide us with whatever proof of identity we may reasonably request. You will keep secure and confidential your account password or any identification we provide you which allows access to the Services.</p>

  <p><strong>Account Circumvention.</strong> You will not circumvent your payment obligations for the Services. You may not authorize others to use your account, and you may not assign or otherwise transfer your user account to any other person or entity.</p>

  <p><strong>Legal Compliance.</strong> You will comply with all applicable state, federal, and local laws while using the Services. You will not copy or distribute the Services without written permission from College Match U.</p>

  <p><strong>Access and Use.</strong> You may only access the Services using authorized means. You will not use the Services for any fraudulent purposes or to cause nuisance, annoyance or inconvenience. It is your responsibility to ensure you have the correct software and equipment for use with the Services. You will only use an access point or data account that you are authorized to use. College Match U reserves the right to terminate your use of the Services if you use an incompatible or unauthorized device.</p>

  <p><strong>Protection of Services and Users.</strong> You will not:</p>
  <ul>
		<li>Create Internet "links" to the Services or "frame" or "mirror" any software on any other server or wireless or Internet-based device.</li>
		<li>Reverse engineer or access the Services (except as permitted by applicable law) in order to, build a competitive product or service, build a product using similar ideas, features, functions or graphics of the Services, or copy any ideas, features, functions or graphics of the Services. </li>
		<li>Launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders the operation and/or performance of the Services. </li>
		<li>Send spam or otherwise duplicative or unsolicited messages in violation of applicable laws through the Services. </li>
		<li>Send or store through or on the Services infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or in violation of third party privacy rights. </li>
		<li>Send or store through or on the Services material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs. </li>
		<li>Interfere with or disrupt the integrity or performance of the Services or the data contained therein.</li>
		<li>Attempt to gain unauthorized access to the Services or its related systems or networks.</li>
		<li>Try to harm the Services in any way.</li>
  </ul>

  <h3>LICENSE GRANT</h3>

  <p>Subject to your compliance with these Terms, College Match U grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Services solely for your own personal, non-commercial purposes. All rights not expressly granted to you are reserved by College Match U and its licensors. </p>

  <h3>PAYMENT TERMS</h3>

  <p>All charges are due immediately and payment will be facilitated by College Match U using the preferred payment method designated in your College Match U account, after which College Match U will send you a receipt by email. If your primary payment method is determined to be expired, invalid or otherwise not able to be charged, you will be notified to update your payment method by College Match U and your account may be suspended.</p>

  <p>College Match U is a subscription service and will auto charge the method of payment on file as frequently as required to keep your account current.</p>

  <h3>CONSENT TO CALL RECORDING</h3>

  <p>You agree that any phone calls to or from College Match U may be monitored or recorded for quality assurance purposes.</p>

  <h3>College Match U INTELLECTUAL PROPERTY</h3>

  <p>College Match U alone (and its licensors, where applicable) shall own all of the right, title and interest (including all related intellectual property rights), in and to the past, present, and future versions of the Services and all content therein. This content shall include, but is not limited to all layout, text, illustrations, instructions, files, images, designs, software, scripts, graphics, photos, sounds, music, videos, information, materials, technology, interactive features, the “look and feel” of the Services, the compilation and arrangement of the Services, College Match U trademarks, all copyrightable material (including source code and object code) and derivative works or enhancements of any of the above, unless ownership rights remain with a user as part of a User Submission, as described in the User Content section below.</p>

  <p>College Match U alone (and its licensors, where applicable) shall also own all of the right, title, and interest (including all related intellectual property right), in and to any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Services. </p>

  <p>These Terms are not a sale and do not convey to you any rights of ownership in or related to the Services, or any intellectual property rights owned by College Match U. The College Match U name, College Match U logo, and the product names associated with the Services are trademarks of College Match U or third parties, and no right or license is granted to use them.</p>

  <h3>Copyright and Alleged Intellectual Property Violations by Users</h3>

  <p>You may have heard of the Digital Millennium Copyright Act (the "DMCA"), as it relates to online service providers, like College Match U, being asked to remove material that allegedly violates someone's copyright. We respect others' intellectual property rights, and we reserve the right to delete or disable content alleged to be infringing any intellectual property rights at our sole discretion, and to terminate the accounts of repeat alleged infringers; to review our complete Copyright Dispute Policy and learn how to report potentially infringing content, please email</p>

  <h3>INDEMNIFICATION</h3>
  <p>By entering into these Terms and using the Services, you agree, to the fullest extent permitted by applicable law, that you shall defend, indemnify and hold College Match U, its licensors and each such party's parent organizations, subsidiaries, affiliates, officers, directors, members, employees, attorneys, assigns and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with:</p>
  <ol>
		<li>your violation or breach of any term of these Terms or any applicable law or regulation;</li>
		<li>your violation of any rights of any third party; or</li>
		<li>your use or misuse of the Services.</li>
  </ol>
  <h3>DISCLAIMER OF WARRANTIES</h3>

  <p>College Match U MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES. College Match U DOES NOT REPRESENT OR WARRANT THAT:</p>
  <ol>
		<li>THE USE OF THE SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA,</li>
		<li>THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,</li>
		<li>ANY STORED DATA WILL BE ACCURATE OR RELIABLE,</li>
		<li>THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS,</li>
		<li>ERRORS OR DEFECTS IN THE SERVICES WILL BE CORRECTED, OR</li>
		<li>THE SERVICE OR THE SERVER(S) THAT MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</li>
  </ol>

  <p>THE SERVICES ARE PROVIDED TO YOU STRICTLY ON AN "AS IS" BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY College Match U. College Match U MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF ANY SERVICES, PRODUCTS OR GOODS OBTAINED BY THIRD PARTIES THROUGH THE USE OF THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY THIRD PARTY SERVICES OR PRODUCTS REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED BY LAW.</p>

  <h3>INTERNET DELAYS</h3>
  <p>College Match U’S SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. College Match U IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.</p>

  <h3>LIMITATION OF LIABILITY</h3>
  <p>College Match U SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF College Match U HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>

  <p>THE LIMITATIONS AND DISCLAIMER IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.</p>

  <h3>NOTICE</h3>
  <p>College Match U may give notice by means of a general notice on the Services, electronic mail to your email address on record in College Match U’s account information, or by written communication sent by first class mail or pre-paid post to your address on record in College Match U's account information. Such notice shall be deemed to have been given upon the expiration of 48 hours after sending.</p>

  <h3>ASSIGNMENT</h3>
  <p>These Terms may not be assigned by you without the prior written approval of College Match U but may be assigned at any time by College Match U to:</p>
  <ol>
		<li>a parent or subsidiary</li>
		<li>an acquirer of assets</li>
		<li>a successor by merger</li>
  </ol>
  <p>Any purported assignment in violation of this section shall be void.</p>

  <h3>TERM AND TERMINATION OF TERMS</h3>
  <p>These Terms are effective upon your access or use of the Services. You or College Match U may terminate your participation in the Services at any time, for any reason and College Match U may prohibit your use of the Services at any time in its sole discretion. These Terms, and any subsequent modification of these Terms, shall remain in effect at all times after you or College Match U terminate your participation or access to the Services.</p>

  <h3>DISPUTES/MANDATORY INDIVIDUAL ARBITRATION</h3>
  <p>Any dispute or claim relating in any way to your use of the Services will be resolved by binding arbitration on an individual basis, rather than in court, except that you may assert claims in small claims court if your claims qualify. ANY ARBITRATION UNDER THESE TERMS WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED. The Federal Arbitration Act and federal arbitration law apply to this agreement.</p>
  <p>“Disputes” or “claims” under this provision shall include, but are not limited to, any dispute, claim or controversy, whether based on past, present, or future events, arising out of or relating to: the Terms and prior versions thereof (including the breach, termination, enforcement, interpretation or validity thereof), the Services, any other goods or services made available through the Services, your relationship with College Match U, the threatened or actual suspension, deactivation or termination of your account with College Match U, payments made by you or any payments made or allegedly owed to you, any promotions or offers made by College Match U, any claims for fraud, defamation, emotional distress, breach of any express or implied contract or covenant, claims arising under federal or state consumer protection laws; claims arising under antitrust laws, claims arising under the Telephone Consumer Protection Act and Fair Credit Reporting Act; and claims arising under the Uniform Trade Secrets Act, Civil Rights Act of 1964, Americans With Disabilities Act, and state statutes, if any, addressing the same or similar subject matters, and all other federal and state statutory and common law claims. All disputes concerning the arbitrability of a claim (including disputes about the scope, applicability, enforceability, revocability or validity of the Arbitration Agreement) shall be decided by the arbitrator, except as expressly provided below.</p>

  <p>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow these Terms as a court would.</p>

  <p>WE EACH AGREE THAT ANY DISPUTE RESOLUTION PROCEEDINGS WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. The arbitrator shall have no authority to consider or resolve any claim or issue any relief on any basis other than an individual basis. The arbitrator shall have no authority to consider or resolve any claim or issue any relief on a class, collective, or representative basis. Other than disputes regarding the validity of the class action waiver contained herein, which disputes may be resolved only by a civil court of competent jurisdiction, all disputes regarding the scope and validity of these Terms will be resolved by the arbitrator.</p>

  <p>If for any reason a claim proceeds in court rather than in arbitration we each waive any right to a jury trial. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>

  <h3>CHOICE OF LAW</h3>

  <p>Except as provided above in the DISPUTES/MANDATORY INDIVIDUAL ARBITRATION section, these Terms will be governed by the laws of California without regard to choice of law principles. This choice of law provision is only intended to specify the use of California law to interpret this Agreement and does not create any other substantive right to non-Californians to assert claims under California law whether by statute, common law, or otherwise.</p>

  <h3>GENERAL</h3>
  <p>No joint venture, partnership, employment, or agency relationship exists between you, College Match U or any third party provider as a result of these Terms or use of the Services. </p>
  <p>If any provision of the Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law. </p>
  <p>The failure of College Match U to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by College Match U in writing. </p>
  <p>These Terms comprise the entire agreement between you and College Match U and supersedes all prior or contemporaneous negotiations, discussions or terms, whether written or oral, between the parties regarding the subject matter contained herein. If any provision of these Terms is or becomes invalid or non-binding, the parties shall remain bound by all other provisions hereof. In that event, the parties will replace the invalid or non- binding provision with provisions that are valid and binding and that have, to the greatest extent possible, a similar effect as the invalid or non-binding provision, given the contents and purpose of these Terms.</p>

</div>

				
      </>
    )}}

export default Terms