// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "charge",
            "endpoint": "https://v24uc5gogl.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "clientDBApi",
            "endpoint": "https://h7noekn8ye.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "clientDB-prod",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "collegematchu-20190503180044-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2oo0uadn0sj8a.cloudfront.net"
};


export default awsmobile;
