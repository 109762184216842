import React from "react";
import SearchForm from "../components/SearchFormNew";
import MatchForm from "../components/MatchFormNew";
import fetch from 'unfetch';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from "../components/ChangingProgressProvider";


class HomePage extends React.Component {
	constructor(props) {
		super(props);
	
	this.state = {
		loading: false,
		showQuery: true,
		showMatchResults: false,
		region: 'West',
		gpa: 3.1,
		sport: 'Softball',
		division: '3',
		gender: 'F',
		actReading: 0,
		actEnglish: 0,
		actMath: 0,
		actScience: 0,
		satMath: 0,
		satrw: 0,
		schoolCount: 0,
		amount: 0,		
		noResults: false,
		matchResults: [],
		tableResults: []
	};
	
	this.onSearchSubmit = this.onSearchSubmit.bind(this);
	this.regionChange = this.regionChange.bind(this);
	this.gpaChange = this.gpaChange.bind(this);
	this.sportChange = this.sportChange.bind(this);
	this.divisionChange = this.divisionChange.bind(this);
	this.genderChange = this.genderChange.bind(this);
	this.actReadingChange = this.actReadingChange.bind(this);
	this.actEnglishChange = this.actEnglishChange.bind(this);
	this.actMathChange = this.actMathChange.bind(this);
	this.actScienceChange = this.actScienceChange.bind(this);
	this.satrwChange = this.satrwChange.bind(this);
	this.satMathChange = this.satMathChange.bind(this);
	this.resetForm = this.resetForm.bind(this);
	this.setNoResults = this.setNoResults.bind(this);
}

componentDidMount() {
	console.log('we mounted home page')
}

	resetForm() {
		this.setState({
				showQuery: true,
				loading: false,
				showMatchResults: false,
				matchResults: [],
				tableResults: [],
				amount: 0,
		});
	}

	extractTitleData(item) {
		const actComposite = (parseInt(item.student_act_english_score) + parseInt(item.student_act_math_score) + parseInt(item.student_act_read_score) + parseInt(item.student_act_science_score)) / 4;
		const satCombined = parseInt(item.student_sat_math_score) + parseInt(item.student_sat_rw_score);
		let satRank = 3;
		let actRank = 3;
		let rank = 3;
		if(satCombined > 0) {
			if(satCombined >= item.sat_combined_75)
				satRank = 1;
			else if(satCombined >= item.sat_combined_25)
				satRank = 2;
		} 
		if(actComposite > 1) {
			if(actComposite > item.act_composite_75)
				actRank = 1;
			else if(actComposite > item.act_composite_25)
				actRank = 2;
		}
		if(actRank < satRank)
			rank = actRank;
		else
			rank = satRank;
		const newOb =  {
			institution_name: item.institution_name,
			rank: rank,
			city: item.city_txt,
			state: item.state_name
		}
		return(newOb);
	}

	buildTableResults() {

		const results = this.state.matchResults.map(this.extractTitleData);
		this.setState({
			tableResults: results,
			loading: false,
			showMatchResults: true,
			showQuery: false
		});
	}

	setNoResults() {
		this.setState({ noResults: true })
		this.resetForm();
	}

	regionChange = (e) => {
		this.setState({ region: e});
	}

	gpaChange = (e) => {
		this.setState({ gpa: e});
}

	sportChange = (e) => {
		this.setState({ sport: e});
	}
	
	divisionChange = (e) => {
		this.setState({ division: e});
	}

	genderChange = (e) => {
		this.setState({ gender: e});
	}

	actReadingChange(event) {
		this.setState({actReading: event});
	}

	actEnglishChange(event) {
			this.setState({
					actEnglish: event
			});
	}

	actMathChange(event) {
			this.setState({
					actMath: event
			});
	}

	actScienceChange(event) {
			this.setState({
					actScience: event
			});
	}

	satrwChange(event) {
			this.setState({
					satrw: event
			});
	}

	satMathChange(event) {
			this.setState({
					satMath: event
			});
	}

	onSearchSubmit = (e) => {
		e.preventDefault();
		this.setState({loading: true, showQuery: false});
		
		const apiString = `https://7leep7xyj1.execute-api.us-east-1.amazonaws.com/default/formProcessWithAuth?gpa=${this.state.gpa}&gender=${this.state.gender}&sportname=${this.state.sport}&division=${this.state.division}&region=${this.state.region}&actmath=${this.state.actMath}&actscience=${this.state.actScience}&actenglish=${this.state.actEnglish}&actread=${this.state.actReading}&satmath=${this.state.satMath}&satrw=${this.state.satrw}&userId=${this.props.user ? this.props.user.username : null}`;
		fetch(apiString)
		.then(r => r.json())
				.then((data) => {
						this.setState({
								showQuery: false,
								loading: true,
								showMatchResults: false,
								matchResults: data.data,
								amount: data.amount,
						});
				})
				.then(() => {
					this.state.matchResults.length > 0 ?
						this.buildTableResults() :
						this.setNoResults();
				});
	}

  render() {
		const loaderStyles = {			
				position : "fixed",
				top : "40%",
				left : "40%",
				justifyContent: "center",
				display: "flex"
		};
    return (
			<>
				{this.state.loading && (					
					<div style={loaderStyles}>
					<ChangingProgressProvider values={[0, 15, 30, 45, 60, 75, 87, 100]}>
					{percentage => (
						<CircularProgressbar 
							value={percentage} 
							text={`${percentage}%`} 
							styles={buildStyles({
								// Rotation of path and trail, in number of turns (0-1)
								rotation: 0.15,
								
						
						 
								// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
								strokeLinecap: 'butt',
						 
								// Text size
								textSize: '16px',
						 
								// How long animation takes to go from one percentage to another, in seconds
								pathTransitionDuration: 0.15,
						 
								// Can specify path transition in more detail, or remove it entirely
								// pathTransition: 'none',
						 
								// Colors
								pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
								textColor: '#50bfff',
								trailColor: '#d6d6d6',
								backgroundColor: '#3e98c7',
							})}
						/>
					)}
				</ChangingProgressProvider>
				</div>
  
				)}

				{this.state.showQuery && (
					<SearchForm
						user={this.props.user}
						noResults={this.state.noResults}
						isPaid={this.props.isPaid}
						gender={this.state.gender}
						genderChange={this.genderChange}
						gpa={this.state.gpa}
						gpaChange={this.gpaChange}
						submit={this.onSearchSubmit}
						regionChange={this.regionChange}
						region={this.state.region}
						sportChange={this.sportChange}
						sport={this.state.sport}
						divisionChange={this.divisionChange}
						division={this.state.division}
						satMath={this.state.satMath}
						satMathChange={this.satMathChange}
						satrw={this.state.satrw}
						satrwChange={this.satrwChange}
						actReading={this.state.actReading}
						actReadingChange={this.actReadingChange}
						actEnglish={this.state.actEnglish}
						actEnglishChange={this.actEnglishChange}
						actMath={this.state.actMath}
						actMathChange={this.actMathChange}
						actScience={this.state.actScience}
						actScienceChange={this.actScienceChange}
						schoolCount={this.schoolCount}		
					/> )}
				{this.state.showMatchResults && (
					<MatchForm 
						matchResults={this.state.matchResults}
						tableResults={this.state.tableResults}
						user={this.props.user}
						isPaid={this.props.isPaid}
						handleSignin={this.props.handleSignin}
						backBtn={this.resetForm}
						region={this.state.region}
						amount={this.state.amount}
					/>
				)}
      </>
    )}}

export default HomePage
