// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:99bc7367-bc45-4d75-9334-111b4f2e560a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TjO5GutEu",
    "aws_user_pools_web_client_id": "6r69k7dgch2v81hset0fb97uni",
    "aws_cloud_logic_custom": [
        {
            "name": "charge",
            "endpoint": "https://v24uc5gogl.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "clientDBApi",
            "endpoint": "https://h7noekn8ye.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "clientDB-prod",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "collegematchu-20190503180044-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2oo0uadn0sj8a.cloudfront.net"
};


export default awsmobile;
